<script setup>
const props = defineProps({
  cars: Array,
  title: String
})

const models = computed(() => {
  const m = {};
  props.cars.forEach(c => m[c.modelName] = c)
  return Object.values(m);
})
</script>

<template>
  <div class="flex flex-wrap gap-x-4 font-extralight text-sm">
    <div>{{ title }}</div>
    <template v-for="car in models">
      <NuxtLink :to="{name: 'model', params: {brand: car.brandName.toLowerDashed(), model: car.modelName.toLowerDashed()}}" class="text-azure">{{ car.brandName.upperCaseFirst() }} {{ car.modelName }}</NuxtLink>
    </template>
  </div>
</template>
