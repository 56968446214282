<script setup>
const props = defineProps({
  name: String,
  type: {
    type: String,
    default: 'text'
  },
  priceFormat: Boolean
})

const emits = defineEmits(['blur','enter'])

const value = defineModel();

const formatIfNecessary = (val) => {
  if (!props.priceFormat) return val;
  val = priceFormat(val.toString().replace(/[^0-9]+/g, ""));
  return val;
}

const inputValue = ref(formatIfNecessary(value.value));

const setValueFormat = () => {
  if (!props.priceFormat) return;
  inputValue.value = parseInt(value.value.toString().replace(/[^0-9]+/g, ""));
}

const updateValueFromInput = () => {
  let val = toRaw(inputValue.value);
  // val = formatIfNecessary(val);
  if (props.priceFormat) {
    val = parseInt(val.toString().replace(/[^0-9]+/g, ""));
  }
  if(value.value === val) {
    inputValue.value = formatIfNecessary(val);
  }
  value.value = val;
  emits('change');
}

watch(
  () => value.value,
  (v) => {
    nextTick(() => inputValue.value = formatIfNecessary(v));
  }
)

</script>

<template>
  <input type="text" class="w-[100px] h-10 rounded border border-gray-400 px-3 text-right text-gray-500 outline-none"
         v-model="inputValue"
         :name="name"
         @blur="updateValueFromInput"
         @focus="setValueFormat"
         @keyup.enter="updateValueFromInput"
  />
</template>
