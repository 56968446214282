<script setup>

const {onWindowEvent} = useWindowEvents();
const {blendEffect} = useBlend();

const props = defineProps({
  opened: Boolean,
  overlay: Boolean,
  withEscHandler: Boolean
});

const emits = defineEmits(['beforeOpen', 'open', 'beforeClose', 'close'])

const opened = ref(props.opened);
const refDrawer = ref();

const open = () => {
  emits('beforeOpen');
  opened.value = true;
  emits('open');
}

const close = () => {
  emits('beforeClose');
  opened.value = false;
}

const onAnimationEnd = () => {
  if (opened.value) {
    emits('open');
  } else {
    emits('close');
  }
}

if (props.withEscHandler) {
  onWindowEvent('keydown', (event) => event.key === "Escape" ? close() : null);
}

blendEffect(opened, true);

defineExpose({
  open,
  close
})

</script>

<template>
  <div ref="refDrawer"
       class="fixed bottom-0 sm:bottom-auto sm:relative container z-60 sm:z-40 sm:min-h-0"
       @touchmove.passive.prevent=""
  >
    <div class="relative bg-white -mx-4 sm:-mx-8 shadow-box">
      <div class="flex w-10 h-10 justify-center items-center rounded bg-gray-200 absolute right-12 sm:right-7 top-4 sm:top-6 cursor-pointer transition-opacity z-60"
           :class="{'opacity-0 pointer-events-none': !opened}"
           @click="close">
        <IconClose class="!w-5 !h-5 stroke-1" />
      </div>
      <Accordion :expanded="opened"
                 class="w-[100vw] sm:w-auto !duration-300"
                 @transitionend="onAnimationEnd">
        <div class="py-6 px-4 sm:px-7">
          <slot />
        </div>
      </Accordion>
    </div>
  </div>
</template>
