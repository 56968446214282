<script setup>

const backend = useBackend();
const route = useRoute();
const analytics = useAnalytics();

const props = defineProps({
  visible: {
    type: Boolean,
    default: true
  }
})

const getBrands = () => {
  return new Promise(success => {
    backend.getBrands().then(result => {
      const allBrands = result.data;
      const activeBrands = allBrands.filter(brand => brand.carsCount)

      success(activeBrands);
    });
  })
}

const {data: brands} = await useAsyncData(() => getBrands());

const expanded = ref(false);

const emits = defineEmits(['searchClick']);
</script>

<template>
  <div class="relative -mx-4 sm:-mx-8 px-4 sm:px-8  shadow-box bg-white">
    <Accordion :expanded="visible">
      <LayoutColumns>
        <LayoutMainColumn noflex>
          <div class="grid grid-cols-4 md:grid-cols-6 lg:grid-cols-6 xl:grid-cols-9 sm:gap-6 mt-6 mb-4 md:my-3 border-b border-b-gray-400 lg:border-b-0 lg:border-r lg:border-r-gray-400 pr-4 -mr-4">
            <template v-for="brand in brands">
              <div :class="{'hidden show-7 md:show-5 xl:show-8':!expanded}">
                <ButtonBrand :icon="brand.svg"
                             :href="{name: 'brand', params: toLowerDashed({brand: brand.name})}"
                             :selected="toLowerDashed(brand.name) === toLowerDashed(route.params.brand)"
                             @click="analytics.analyticsLayer({event: 'configurator_brand', brand: brand.name})"
                />
              </div>
            </template>
            <ButtonBrandsMore v-model:toggled="expanded" />
          </div>
        </LayoutMainColumn>
        <LayoutSideColumn class="!bg-transparent !pt-0 pb-2">
          <div class="lg:pt-10">
            <ButtonPlain class="mx-auto">
              <template #leftSlot>
                <IconSettings />
              </template>
              <span @click="emits('searchClick')">Wyszukiwanie zaawansowane</span>
            </ButtonPlain>
          </div>
        </LayoutSideColumn>
      </LayoutColumns>
    </Accordion>
  </div>
</template>
