<script setup>
const toggled = defineModel('toggled',{default: () => false});
const sign = computed(() => toggled.value ? "M7 7H10.5M7 7H3.5" : "M7 3.5V7M7 7V10.5M7 7H10.5M7 7H3.5");
const label = computed(() => toggled.value ? "mniej marek" : "więcej marek");

const emits = defineEmits(['click']);

const clickHandle = () => {
  toggled.value = !toggled.value;
  emits('click', toggled);
}

</script>

<template>
  <div class="aspect-square sm:aspect-auto p-1 sm:p-0 select-none" @click="clickHandle">
    <div class="w-20 h-20 justify-self-center flex flex-col justify-center items-center cursor-pointer">
      <div class="flex flex-col justify-center items-center rounded-full w-6 h-6 border border-gray-700">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path :d="sign" stroke="#3F3F46" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      <div class="text-xs font-extralight mt-1">{{ label }}</div>
    </div>
  </div>
</template>
